import React from 'react'

const Blog = () => {
  return (
    <div>
        <h1>Blog</h1>
    </div>
  )
}

export default Blog